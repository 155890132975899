<template>
  <v-snackbar
    top
    right
    :timeout="3000"
    :color="$store.state.theme.snackbarColor"
    style="width: 100; max-width: 100%;"
    v-model="snackbarModel"
  >
    <span v-html="message"></span>

    <template v-slot:action="{ attrs }">
      <v-btn color="white" text v-bind="attrs" @click="hideSnackbar">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('theme', ['snackbar', 'snackbarMessage', 'snackbarColor']),
    snackbarModel: {
      get() {
        return this.snackbar;
      },
      set(needShow) {
        if (!needShow) this.hideSnackbar();
      },
    },
    message() {
      if (this.snackbarMessage) return this.snackbarMessage;
      if (this.snackbarColor == 'error') return 'حدث خطأ';
      return 'تمت العملية بنجاح';
    },
  },
  methods: {
    hideSnackbar() {
      this.$store.commit('theme/UPDATE_SNACKBAR_SHOW', false);
    },
  },
};
</script>

<style lang="scss" scoped></style>
