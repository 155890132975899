export default [
  {
    name: 'entries',
    path: '/entries/:slug',
    // props: (route) => ({ slug: route.params.slug || 'all' }),
    // props: (route) => ({ query: route.query.q, params: { slug: 'all' } }),
    // params: { slug: 'all' },
    component: () => import('@/views/entries/Entry.vue'),
    meta: {
      title: 'المحتوى',
    },
  },
];
