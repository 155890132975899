import config from '@/config/config';
import { getSavedConfigTheme, saveConfigTheme } from '@/helper/localStorage';
import store from '@/store';

export function getThemeConfig() {
  const savedTheme = getSavedConfigTheme();
  const themeConfig = savedTheme || config;
  return themeConfig;
}

export function saveDefaultConfigTheme() {
  if (getSavedConfigTheme() == null) {
    window.location.reload();
    saveConfigTheme(config);
  }
}


export function getColorAsCSSVars() {
  let ret = {};
  const { colors } = store.state.style;

  Object.keys(colors).forEach((key) => {
    ret[`--${key}-color`] = colors[key];
  });

  ret[`--list-background`] = `var(--${
    store.state.theme.dark ? 'secondary' : 'primary'
  }-color)`;

  return ret;
}