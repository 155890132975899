export default {
  namespaced: true,
  state: {
    isSortChanged: false,
  },
  mutations: {
    UPDATE_SORTING(state, isSortChanged) {
      state.isSortChanged = isSortChanged;
    },
  },
};
