const initState = () => ({
  title: 'تأكيد',
  message: 'هل انت متأكد ؟',
  subMessage: '',
  isOpenConfirm: false,
  isConfirm: false,
  color: 'red',
  confirmHandler: async () => {
    console.log({ confirmHandler: 'confirm' });
    return true;
  },
});

export default {
  namespaced: true,
  state: { ...initState() },
  mutations: {
    OPEN_CONFIRM(state, { title, message, subMessage, color, confirm }) {
      if (state.isOpenConfirm) return;

      const defaultState = initState();
      state.title = title || defaultState.title;
      state.message = message || defaultState.message;
      state.subMessage = subMessage || defaultState.subMessage;
      state.color = color || defaultState.color;
      state.confirmHandler = confirm || defaultState.confirmHandler;

      state.isOpenConfirm = true;
      state.isConfirm = false;
    },
    CLOSE_CONFIRM(state) {
      state.isOpenConfirm = false;
      state.isConfirm = false;
    },
    BACK_CONFIRM_WITHOUT_CLOSE(state) {
      state.isConfirm = false;
    },
    CONFIRM(state) {
      state.isConfirm = true;
    },
  },
  actions: {
    openConfirm({ commit }, { title, message, subMessage, color, confirm }) {
      commit('OPEN_CONFIRM', { title, message, subMessage, color, confirm });
    },
    closeConfirm({ commit }) {
      commit('CLOSE_CONFIRM');
    },
    async confirm({ commit, state }) {
      commit('CONFIRM');
      const status = await state.confirmHandler();
      if (status) commit('CLOSE_CONFIRM');
      else commit('BACK_CONFIRM_WITHOUT_CLOSE');
    },
  },
};
