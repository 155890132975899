export default [
  {
    name: 'settings',
    path: '/settings',
    component: () => import('@/views/settings/Settings.vue'),
    meta: {
      title: 'الاعدادات',
    },
    beforeEnter(to, from, next) {
      return next();
    },
  },
];
