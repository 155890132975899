import axiosIns from '@/libs/http/http';
import { pause } from '@/utls/jsCommon';

export default {
  namespaced: true,
  state: {
    types: [],
    loading: false,
  },
  mutations: {
    INIT_TYPES(state, types) {
      state.types = types;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    ADD_TYPE(state, type) {
      state.types.push(type);
    },
    REMOVE_TYPE(state, { id }) {
      const indexOfType = state.types.findIndex((x) => x.id == id);
      if (indexOfType != -1) state.types.splice(indexOfType, 1);
    },
  },
  getters: {
    nameById: (state) => (id) => {
      const type = state.types.find((x) => x.id == id);
      const { name } = type || {};
      return name;
    },
  },
  actions: {
    addType({ commit }, { id, slug, name }) {
      const type = { id, slug, name };
      commit('ADD_TYPE', type);
    },
    removeType({ commit }, { id }) {
      commit('REMOVE_TYPE', id);
    },
    async loadTypes({ commit }) {
      commit('SET_LOADING', true);
      return axiosIns
        .get('app/content-type', {
          params: { language: 'ar', maxResultCount: 1000 },
        })
        .then((response) => {
          const { items, totalCount } = response.data;

          const types = items.reduce((p, c) => {
            const { name } = c.contentTypeLanguages[0] || {};
            const data = [...p, { id: c.id, slug: c.slug, name }];
            return data;
          }, []);

          commit('INIT_TYPES', types);
          commit('SET_LOADING', false);
        })
        .catch((error) => {})
        .finally(() => {});
    },
  },
};
