import store from '@/store';

function showSnackbar(message, color) {
  store.commit('theme/UPDATE_SNACKBAR_MESSAGE', {
    message: message,
    color: color,
  });
  store.commit('theme/UPDATE_SNACKBAR', true);
}

export function showSnackbarSuccessMessage(message) {
  showSnackbar(message, 'success');
}

export function showSnackbarFailMessage(message) {
  showSnackbar(message, 'error');
}

export function showSnackbarWarningMessage(message) {
  showSnackbar(message, 'warning');
}
