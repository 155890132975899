<template>
  <main-layout />
</template>
<script>
import MainLayout from './layouts/MainLayout.vue';
import { saveDefaultConfigTheme } from './libs/theme/utls';
export default {
  name: 'App',
  components: {
    MainLayout,
  },
  data: () => ({
    //
  }),
  created() {
    saveDefaultConfigTheme();
  },
};
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css? family=Cairo:300,400,700&display=swap');
@import '@/sass/mixin.scss';

$body-font-family: 'Cairo', sans-serif !important;

* {
  @include scrollbars(8px, #b0b0b0, #e3e3e3);
}

.v-application {
  font-family: $body-font-family;
}
</style>
