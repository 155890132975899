import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import config from '@/config/config';
import vueDebounce from 'vue-debounce';
import draggable from 'vuedraggable';
import service from './utls/utils';
import rules from './validations';


import axiosIns from './libs/http/http';
import VueMask from 'v-mask';
import { VueMaskDirective } from 'v-mask';
import CKEditor from '@ckeditor/ckeditor5-vue2';

import './plugins/axios';




Vue.use(vueDebounce);
Vue.use(VueMask);
Vue.use(CKEditor);

Vue.prototype.$http = axiosIns;
Vue.prototype.$config = config;
Vue.prototype.$service = service;
Vue.prototype.$rules = rules;

Vue.config.productionTip = false;
Vue.directive('mask', VueMaskDirective);
Vue.component('draggable', draggable);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
