import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import config from '@/config/config';
import store from '../store';
Vue.use(Vuetify);

export default new Vuetify({
  rtl: store.state.theme.isRTL,
  customVariables: ['@/assets/variables.scss'],
  treeShake: true,
  options: {
    customProperties: true,
    // ...store.state.style.colors
  },
  theme: {
    dark: store.state.theme.dark,
    themes: {
      light: { ...config.colors, background: '#eeeff3' },
      dark: { ...config.colors, background: '#1E1E1E' },
    },
  },
});
