export const saveAccessToken = (token) => {
  localStorage.setItem('accessToken', token);
};

export const getAccessToken = () => {
  return localStorage.getItem('accessToken') || '';
};

export const saveUserData = (userData) => {
  localStorage.setItem('userData', JSON.stringify(userData));
};

export const getUserData = () => {
  return JSON.parse(localStorage.getItem('userData') || '{}');
};

export const clearSorageData = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('userData');
};

export const saveCMSInformation = (information) => {
  localStorage.setItem('cms-information', JSON.stringify(information));
};

export const getCMSInformation = () => {
  return JSON.parse(localStorage.getItem('cms-information') || '{}');
};

export const getAppVersion = () => {
  return localStorage.getItem('appVersion') || '';
};

export const saveAppVersion = (version) => {
  localStorage.setItem('appVersion', version);
};

export function isDarkTheme() {
  return localStorage.getItem('dark') == 'true';
}

export function saveDarkTheme(isDark) {
  localStorage.setItem('dark', isDark);
}

export function getSavedConfigTheme() {
  // this theme is (config from "@/config");
  const savedTheme = localStorage.getItem('theme');
  return (savedTheme && JSON.parse(savedTheme)) || null;
}

export function saveConfigTheme(newConfig) {
  localStorage.setItem('theme', JSON.stringify(newConfig));
}
