import { appPermissions } from '@/libs/auth/permissions/appPermissions';
import { isHasDynamicContentTypePermission } from '@/libs/auth/permissions/dynamic/dynamicContentTypePermission';

const permissionsShow = (permissions) => () => {
  const { isCanRead } = permissions();
  return isCanRead;
};

const navigationItems = [
  {
    title: 'الصفحة الرئيسية',
    route: 'dashboard-home',
    icon: 'mdi-home',
  },
  {
    title: 'ادارة المستخدمين',
    route: 'users',
    icon: 'mdi-account-multiple',
    show: permissionsShow(appPermissions.users),
  },
  {
    title: 'المحتوى',
    code: 'entries',
    route: { name: 'entries', params: { slug: 'all' }, query: {} },
    icon: 'mdi-note-text',
    children: [],
    onClickExpand: true,
    expandLoading: true,
    show: permissionsShow(appPermissions.entries),
  },
  {
    title: 'انواع القوائم',
    route: 'nav-types',
    icon: 'mdi-format-list-bulleted-type',
    show: permissionsShow(appPermissions.navTypes),
  },
  // {
  //   title: 'القوائم',
  //   route: 'navigations',
  //   icon: 'mdi-dots-horizontal',
  //   hide: $service.hideFrom((v) => [v == 4 || v == 1]),
  // },
  {
    title: 'المحتوى الثابت',
    route: 'pages',
    icon: 'mdi-format-page-break',
    show: permissionsShow(appPermissions.pages),
  },
  {
    title: 'حالات المحتوى',
    route: 'content-status',
    icon: 'mdi-alert-circle-check-outline',
    show: permissionsShow(appPermissions.contentStatus),
  },
  {
    title: 'هيكلية المحتوى',
    route: 'content-type',
    icon: 'mdi-align-horizontal-right',
    show: permissionsShow(appPermissions.contentType),
  },
  {
    title: 'التصنيفات',
    route: 'category',
    icon: 'mdi-ab-testing',
    show: permissionsShow(appPermissions.category),
  },
  {
    title: 'المحتوى المتغير',
    route: 'dynamic-content',
    icon: 'mdi-application-parentheses-outline',
    show: permissionsShow(appPermissions.dynamicContent),
  },
  {
    title: 'ادارة الادوار',
    route: 'roles-management',
    icon: 'mdi-timeline-check-outline',
    show: permissionsShow(appPermissions.roles),
  },
];
export default navigationItems;

export const defaultNavigation = 'dashboard-home';

const getEntriesNavigation = () => {
  const entriesNavigation = navigationItems.find((x) => x.code == 'entries');
  if (!entriesNavigation) return;
  return entriesNavigation;
};

export const buildEntriesChildsTypeMenu = (contentTypesChilds) => {
  const newContentMenuChild = contentTypesChilds
    .map((type) => {
      const { id, slug, name } = type;
      if (!isHasDynamicContentTypePermission(id)) return;
      return {
        title: name,
        route: {
          name: 'entries',
          params: { slug, title: name },
          query: { id: id.toString() },
        },
        icon: 'mdi-card-text-outline',
      };
    })
    .filter((x) => !!x);

  const entriesNavigation = getEntriesNavigation();
  entriesNavigation.children = newContentMenuChild;
};

export const setEntriesNavigationLoading = (loading) => {
  const entriesNavigation = getEntriesNavigation();
  entriesNavigation.expandLoading = loading;
};
