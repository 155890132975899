export function doAfterUserIdlingInactivity(seconds, callback) {
  const idleDurationSecs = seconds; // X number of seconds
  let idleTimeout; // variable to hold the timeout, do not modify

  const resetIdleTimeout = function () {
    // Clears the existing timeout
    if (idleTimeout) clearTimeout(idleTimeout);
    // Set a new idle timeout to load the redirectUrl after idleDurationSecs
    idleTimeout = setTimeout(callback, idleDurationSecs * 1000);
  };

  // Init on page load
  resetIdleTimeout();

  // Reset the idle timeout on any of the events listed below
  ['click', 'touchstart', 'mousemove'].forEach((evt) =>
    document.addEventListener(evt, resetIdleTimeout, false),
  );
}

export function nFormatter(num, digits) {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
    : '0';
}

export function getCurrentDate() {
  var currentdate = new Date();
  var datetime =
    currentdate.getDate() +
    '-' +
    (currentdate.getMonth() + 1) +
    '-' +
    currentdate.getFullYear() +
    ' ' +
    currentdate.getHours() +
    ':' +
    currentdate.getMinutes() +
    ':' +
    currentdate.getSeconds();

  return datetime;
}

export function getSrcFromFile(input, onload) {
  if (input == null) return;
  var reader = new FileReader();
  reader.onload = (e) => {
    const srcResullt = e.target.result;
    onload(srcResullt, e);
  };
  reader.readAsDataURL(input);
}

export function createFileFromBlob(blob, name, type, lastModified) {
  if (!name) throw new Error('file name required');
  if (!type) throw new Error('file type required');

  var newfile = new File([blob], name, {
    type: type,
    lastModified: lastModified || new Date().getTime(),
  });

  return newfile;
}

export function splitQuotedText(text) {
  return text.split(/"(.*?)"/);
}

export function getErrorDetails(error) {
  try {
    const { data: { error: { details, code, message } = {} } = {}, status } =
      error.response || {};

    return { message: message || details || '', details, code, status };
  } catch (error) {
    return {};
  }
}
