<template>
  <v-list nav class="text-center" color="primary">
    <v-list-item>
      <v-list-item-title class="d-flex flex-column justify-center align-center">
        <b style="color: #fff">CMS {{ appVersion }}</b>
        <v-divider></v-divider>
      </v-list-item-title>
    </v-list-item>
  </v-list>
</template>

<script>
import { getAppVersion, saveAppVersion } from '@/helper/localStorage';
import { mapGetters } from 'vuex';
import { doLogout } from '@/libs/auth/authHelper';

export default {
  computed: {
    ...mapGetters(['appVersion']),
  },
  methods: {
    reloginIfNewUpdate() {
      var version = getAppVersion();
      if (version == this.appVersion) return;
      saveAppVersion(this.appVersion);
      if (this.$route.name != 'login') doLogout();
    },
  },
  created() {
    this.reloginIfNewUpdate();
  },
};
</script>
