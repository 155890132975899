import { defaultNavigation } from '../../config/navigation';

export default [
  {
    path: '/',
    redirect: {
      name: defaultNavigation || 'login',
    },
  },
];
