import { getUserData } from "@/helper/localStorage";

export default {
  namespaced: true,
  state: {
    userData: getUserData(),
    item: {},
    dialog: false,
    isEdit: false,
  },
  getters: {
    username: (state) => {
      if (!state.userData || !state.userData.user) return '';
      return state.userData.user.userName;
    },
    isAuthenticated: (state) => {
      if (!state.userData || !state.userData.user) return false;
      return state.userData.user.isAuthenticated;
    },
    grantedPolicies: (state) => {
      const userData = state.userData || {};
      const { grantedPolicies = {}, grantedDynamicPolicies = {} } = userData;

      const allGrantedPolicies = {
        ...grantedPolicies,
        ...grantedDynamicPolicies,
      };

      return allGrantedPolicies;
    },
  },
  mutations: {
    UPDATE_ITEM(state, val) {
      state.item = val;
    },
    TOGGLE_DIALOG(state) {
      state.dialog = !state.dialog;
      state.isEdit = false;
    },
    TOGGLE_EDIT(state) {
      state.isEdit = true;
      state.dialog = true;
    },
    SET_USER_DATA(state, userData) {
      state.userData = userData;
    },
  },
  actions: {},
};
