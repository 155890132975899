import { appPermissions } from '@/libs/auth/permissions/appPermissions';
import { beforeEnterCheckPermission } from './utils/routePermissions';

export default [
  {
    name: 'nav-types',
    path: '/navtypes',
    component: () => import('@/views/navTypes/NavType.vue'),
    meta: {
      title: 'انواع القوائم',
    },
    beforeEnter(to, from, next) {
      const { has, redirectTo } = beforeEnterCheckPermission(
        appPermissions.navTypes,
      );
      if (has) return next();
      else return next(redirectTo);
    },
  },
];
